'use strict';

angular.module('enervexSalesappApp').controller('LineitemSourcesCtrl', function($scope, LineitemSource, _) {
	$scope.allItems = [];
	$scope.offset = 0;
	$scope.limit = 50;
	function fetch() {
		LineitemSource.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(items){
			if (items.length > 0) {
				$scope.allItems = $scope.allItems.concat(items);
				$scope.offset = $scope.offset + $scope.limit;
				fetch();
			} else {
				$scope.items = _.sortBy($scope.allItems, function(item){
					return item.sortOrder
				})
			}
		});
	}
	fetch();
	$scope.delete = function(item) {
		LineitemSource.delete({
			id: item._id
		});
		$scope.allItems = _.filter($scope.allItems, function(r){
			return r._id != item._id
		})
		// $scope.applyFilter()
		$scope.items = $scope.allItems;
	}
});
